import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import httpService, {
  fetcher,
  fetcherWithNormalizer,
  getParams,
} from 'src/utils/httpService';
import useSWR, { mutate } from 'swr';
import { ENDPOINTS } from '../../config';
import { getStatus } from './helper';
import { BackendZaehlpunkt, Status, Zaehlpunkt } from './types';

const NormalizeFromBackend = (data: BackendZaehlpunkt[]) =>
  data.map((zp) => ({
    ...zp,
    status: getStatus(zp.status),
    ...(zp.zaehlernummern || (zp.zaehlernummernStrom && zp.zaehlernummernWaerme)
      ? {}
      : { zaehlernummern: [{ zaehlernummer: zp.zaehlernummer }] }),
  }));

const NormalizeToBackend = ({
  status,
  ...data
}: Zaehlpunkt): Omit<BackendZaehlpunkt, 'zaehlernummer'> => {
  return {
    ...data,
    status: status ? Status.aktiv : Status.inaktiv,
  };
};

export const useZaehlpunkte = ({
  filterByLiegenschaftId = null,
  shouldFetch = true,
}: any = {}) => {
  const url = ENDPOINTS.API_ZAEHLPUNKTE;

  const { data, error } = useSWR<Zaehlpunkt[]>(
    shouldFetch ? url : null,
    fetcherWithNormalizer(NormalizeFromBackend),
    { shouldRetryOnError: false, revalidateOnFocus: false }
  );

  const zaehlpunkte = React.useMemo(
    () =>
      filterByLiegenschaftId
        ? data?.filter(
            (zp: any) =>
              !!zp.liegenschaften.find(
                (ls: any) => filterByLiegenschaftId === ls.id
              )
          )
        : data,
    [filterByLiegenschaftId, data]
  );

  const refetch = React.useCallback(() => {
    mutate(url);
  }, [url]);

  const { enqueueSnackbar } = useSnackbar();

  const createZaehlpunkt = React.useCallback(
    async (data: Omit<Zaehlpunkt, 'id'>) => {
      const response = await httpService
        .post(ENDPOINTS.API_ZAEHLPUNKTE, data)
        .catch((e) => {
          enqueueSnackbar('Zählpunkt konnte nicht erstellt werden', {
            variant: 'error',
          });
          return e;
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const updateZaehlpunkt = React.useCallback(
    async (id: string, data: Zaehlpunkt) => {
      const response = await httpService
        .put(`${ENDPOINTS.API_ZAEHLPUNKTE}/${id}`, NormalizeToBackend(data))
        .catch((e) => {
          enqueueSnackbar('Zählpunkt konnte nicht aktualisiert werden', {
            variant: 'error',
          });
          return e;
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const deleteZaehlpunkt = React.useCallback(
    async (id) => {
      const response = await httpService
        .delete(`${ENDPOINTS.API_ZAEHLPUNKTE}/${id}`)
        .catch(() => {
          enqueueSnackbar('Zählpunkt konnte nicht gelöscht werden', {
            variant: 'error',
          });
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const exportZaehlpunkte = React.useCallback(async () => {
    return httpService.get(ENDPOINTS.API_ZAEHLPUNKTE_EXPORT).catch((e) => {
      enqueueSnackbar('Zählpunkte konnten nicht exportiert werden', {
        variant: 'error',
      });
      return e;
    });
  }, [enqueueSnackbar]);

  return {
    createZaehlpunkt,
    updateZaehlpunkt,
    deleteZaehlpunkt,
    exportZaehlpunkte,
    zaehlpunkte: zaehlpunkte || [],
    isLoading: !error && !data,
    isError: error,
  };
};

interface UseZaehlerstaende {
  zaehlerId: string | boolean;
  anzahl?: number | boolean;
}

export const useZaehlerstaende = ({
  zaehlerId,
  anzahl = false,
}: UseZaehlerstaende) => {
  const endpoint = React.useMemo(
    () =>
      zaehlerId
        ? `${ENDPOINTS.API_ZAEHLPUNKTE_ZAEHLERSTAENDE(zaehlerId)}${
            anzahl ? `?anzahl=${anzahl}` : ''
          }`
        : null,
    [anzahl, zaehlerId]
  );

  const { data, error } = useSWR(endpoint, fetcher);

  const { enqueueSnackbar } = useSnackbar();

  const refetch = React.useCallback(() => {
    mutate(endpoint);
  }, [endpoint]);

  const handleGetZaehlerstaende = React.useCallback(
    ({ zaehlerId, anzahl }) =>
      httpService
        .get(ENDPOINTS.API_ZAEHLPUNKTE_ZAEHLERSTAENDE(zaehlerId), {
          ...getParams({ anzahl }),
        })
        .catch((e) => {
          enqueueSnackbar('Zählerstände konnten nicht ermittelt werden', {
            variant: 'error',
          });
          return e;
        }),
    [enqueueSnackbar]
  );

  const handleAddZaehlerstaende = React.useCallback(
    async (zaehlpunktId, data) => {
      const response = await httpService
        .put(ENDPOINTS.API_ZAEHLPUNKTE_ZAEHLERSTAENDE(zaehlpunktId), data)
        .catch(() => {
          enqueueSnackbar('Zählerstand konnte nicht hinzugefügt werden', {
            variant: 'error',
          });
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const handleDeleteZaehlerstand = React.useCallback(
    async (zaehlpunktId, zeitpunkt) => {
      const response = await httpService
        .delete(
          ENDPOINTS.API_ZAEHLPUNKTE_ZAEHLERSTAENDE_ID_ZEITPUNKT(
            zaehlpunktId,
            zeitpunkt
          )
        )
        .catch((e) => {
          enqueueSnackbar('Zählerstand konnte nicht gelöscht werden', {
            variant: 'error',
          });
          return e;
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const handleUpdateZaehlerstaende = React.useCallback(
    async (zaehlpunktId, data) => {
      const response = await httpService
        .put(ENDPOINTS.API_ZAEHLPUNKTE_ZAEHLERSTAENDE(zaehlpunktId), data)
        .catch((e) => {
          enqueueSnackbar('Zählerstände konnten nicht aktualisiert werden', {
            variant: 'error',
          });
          return e;
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const handleExportZaehlerstaende = React.useCallback(async () => {
    return httpService.get(ENDPOINTS.API_ZAEHLERSTAENDE_EXPORT).catch((e) => {
      enqueueSnackbar('Zählerstände konnten nicht exportiert werden', {
        variant: 'error',
      });
      return e;
    });
  }, [enqueueSnackbar]);

  return {
    handleGetZaehlerstaende,
    handleAddZaehlerstaende,
    handleDeleteZaehlerstand,
    handleUpdateZaehlerstaende,
    handleExportZaehlerstaende,
    zaehlerstaende: data || [],
    isLoading: !error && !data,
    isError: error,
  };
};

interface UseEnergiemengen {
  zaehlerId: string | boolean;
  anzahl?: number | boolean;
}
export const useEnergiemengen = ({
  zaehlerId,
  anzahl = false,
}: UseEnergiemengen) => {
  const endpoint = React.useMemo(
    () =>
      zaehlerId
        ? `${ENDPOINTS.API_ZAEHLPUNKTE_MENGEN(zaehlerId)}${
            anzahl ? `?anzahl=${anzahl}` : ''
          }`
        : null,
    [anzahl, zaehlerId]
  );

  const { data, error } = useSWR(endpoint, fetcher);

  const refetch = React.useCallback(() => {
    mutate(endpoint);
  }, [endpoint]);

  const { enqueueSnackbar } = useSnackbar();

  const handleGetEnergiemengen = React.useCallback(
    ({ zaehlerId, anzahl }) =>
      httpService
        .get(ENDPOINTS.API_ZAEHLPUNKTE_MENGEN(zaehlerId), {
          ...getParams({ anzahl }),
        })
        .catch((e) => {
          enqueueSnackbar('Energiemengen konnten nicht ermittelt werden', {
            variant: 'error',
          });
          return e;
        }),
    [enqueueSnackbar]
  );

  const handleAddEnergiemengen = React.useCallback(
    async (zaehlerId, data) => {
      const response = await httpService
        .put(
          ENDPOINTS.API_ZAEHLPUNKTE_MENGEN(zaehlerId),
          data.map((i: any) => ({
            ...i,
            zeitraumVon: format(i.zeitraumVon, 'yyyy-MM-dd'),
            zeitraumBis: format(i.zeitraumBis, 'yyyy-MM-dd'),
          }))
        )
        .catch(() => {
          enqueueSnackbar('Energiemengen konnten nicht hinzugefügt werden', {
            variant: 'error',
          });
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const handleDeleteEnergiemenge = React.useCallback(
    async (zaehlerId, zeitpunktVon) => {
      const response = await httpService
        .delete(
          ENDPOINTS.API_ZAEHLPUNKTE_MENGEN_DELETE(zaehlerId, zeitpunktVon)
        )
        .catch((e) => {
          enqueueSnackbar('Energiemenge konnte nicht gelöscht werden', {
            variant: 'error',
          });
          return e;
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const handleUpdateEnergiemenge = React.useCallback(
    async (zaehlpunktId, data) => {
      const response = await httpService
        .put(ENDPOINTS.API_ZAEHLPUNKTE_ZAEHLERSTAENDE(zaehlpunktId), data)
        .catch((e) => {
          enqueueSnackbar('Energiemenge konnte nicht aktualisiert werden', {
            variant: 'error',
          });
          return e;
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const handleExportEnergiemengen = React.useCallback(async () => {
    return httpService.get(ENDPOINTS.API_MENGEN_EXPORT).catch((e) => {
      enqueueSnackbar('Energiemengen konnten nicht exportiert werden', {
        variant: 'error',
      });
      return e;
    });
  }, [enqueueSnackbar]);

  return {
    handleGetEnergiemengen,
    handleAddEnergiemengen,
    handleDeleteEnergiemenge,
    handleUpdateEnergiemenge,
    handleExportEnergiemengen,
    energiemengen: data || [],
    isLoading: !error && !data,
    isError: error,
  };
};
